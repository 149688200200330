import type { GetJobListDto, GetSlotJobListDto } from 'api/dto/GetJobListDto';
import type { GetJobListResponse, GetJobSlotListResponse } from 'api/response/GetJobListResponse';
import type { IDeliveryZoneDto } from './dto/DeliveryZoneDto';
import { RequestAPI, type APIResponse } from './Requests';
import { ADMIN_V1_API_PREFIX, API_URL_2 } from 'config';
import type { DashboardInfoDto } from './dto/DashboardInfoDto';

interface DashboardRequestsInterface {
  getInfo(): Promise<APIResponse<DashboardInfoDto>>;

  getJobList(data: GetJobListDto): Promise<APIResponse<GetJobListResponse[]>>;

  getSlotJobList(data: GetSlotJobListDto): Promise<APIResponse<GetJobSlotListResponse>>;

  getDeliveryZones(): Promise<APIResponse<IDeliveryZoneDto[]>>;
}

export const DashboardRequests: DashboardRequestsInterface = {
  getInfo: () => RequestAPI.get(`${API_URL_2 + ADMIN_V1_API_PREFIX}dashboard/get-info`),

  getSlotJobList: (data) => {
    delete data.page;
    return RequestAPI.post(`${API_URL_2 + ADMIN_V1_API_PREFIX}jobs/get-list-slot-delivery`, data);
  },

  getJobList: (data) => RequestAPI.post(`${API_URL_2 + ADMIN_V1_API_PREFIX}jobs/get-list`, data),

  getDeliveryZones: () =>
    RequestAPI.get(`${API_URL_2}delivery/v1/zones?page[size]=100&page[current]=1`),
};
