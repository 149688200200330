import { observer } from 'mobx-react-lite';
import React from 'react';
import { mainStore, DynamicFrameName } from 'stores/MainStore';
import { dataStore } from 'stores/DataStore';
import { generateHtmlClasses } from 'utils/htmlClasses';
import { authStore } from 'stores/AuthStore';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_WIDTH } from 'config';

export default observer(() => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` });

  const handleToggle = (frame: DynamicFrameName) => {
    mainStore.setActiveDynamicFrame(frame);
    if (!isMobile) {
      authStore.setIsFrameTeamsHidden(false);
    }
  };

  return (
    <>
      <div
        className={generateHtmlClasses('button-circle _unassigned icon icon-pin', {
          _active: dataStore.unassignedOrdersCount,
        })}
        onClick={() => handleToggle('unassigned')}
        data-button="unassigned-orders"
      >
        {dataStore.unassignedOrdersCount > 0 && (
          <div className="button-circle__label">{dataStore.unassignedOrdersCount}</div>
        )}
      </div>
      <div
        className={generateHtmlClasses('button-circle _teamless icon icon-team', {
          _active: dataStore.teamlessCouriersCount,
        })}
        onClick={() => handleToggle('teamless')}
        data-button="teamless-couriers"
      >
        {dataStore.teamlessCouriersCount > 0 && (
          <div className="button-circle__label">{dataStore.teamlessCouriersCount}</div>
        )}
      </div>
    </>
  );
});
