import { observer } from 'mobx-react-lite';
import React from 'react';
import { mainStore } from '../../stores/MainStore';
import { settingsStore } from './Warehouses/store';

type HeaderProps = {
  title: string;
  onBackClick?: () => void;
};

export default observer(({ title, onBackClick }: HeaderProps) => {
  const handleCloseClick = () => {
    mainStore.closePopupSettings();
    settingsStore.setActiveFrame('home');
  };

  return (
    <div className="settings-frame__header header">
      {onBackClick && (
        <div
          className="header__back icon icon-chevron-up"
          onClick={onBackClick}
          data-button="back"
        />
      )}
      <div className="header__title">{title}</div>
      <div
        className="header__close icon icon-close"
        onClick={handleCloseClick}
        data-button="close"
      />
    </div>
  );
});
