import { TaskWithRel } from 'api/response/GetJobListResponse';
import { TaskState } from 'api/types/Task';
import { searchStore } from 'components/Search/store';
import { MOBILE_WIDTH } from 'config';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { authStore } from 'stores/AuthStore';
import { dataStore } from 'stores/DataStore';
import { dateTimeStore } from 'stores/DateTimeStore';
import { mainStore } from 'stores/MainStore';
import { generateHtmlClasses } from 'utils/htmlClasses';

interface OrderInfoBarProps {
  orderId: string;
  jobId: string;
  task: TaskWithRel;
  externalId: string;
}

export default observer(
  ({ externalId, orderId, jobId, task: { task, address } }: OrderInfoBarProps) => {
    const isActive = mainStore.selectedOrder.taskId === task.id;
    const [progress, setProgress] = useState(100);
    const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` });

    const isTaskCompleted = [TaskState.COMPLETED, TaskState.CANCELED, TaskState.FAILED].includes(
      task.state as TaskState,
    );

    const order = useMemo(
      () =>
        dataStore.getServerOrderById(orderId, jobId) || dataStore.getLocalOrderById(orderId, jobId),
      [jobId, orderId],
    );
    const sla = order?.order.deliverByMax ? new Date(order.order.deliverByMax) : null;
    const etaDiffInMin = sla ? dateTimeStore.getDateDiffInMinutes(sla.getTime(), Date.now()) : 0;

    const etaTime = {
      value: dateTimeStore.getApproximateTime(etaDiffInMin).split(' ')[0],
      label: dateTimeStore.getApproximateTime(etaDiffInMin).split(' ')[1],
    };

    const handleOrderClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      searchStore.resetSearch();
      mainStore.setSelectedOrder({
        externalId,
        id: orderId,
        jobId,
        taskId: task.id,
        source: 'map',
      });
      if (!isMobile) {
        authStore.setIsFrameTeamsHidden(false);
      }
      mainStore.setActiveDynamicFrame('detail');
    };

    useEffect(() => {
      if (dataStore.serverTime <= task.completeByMin) {
        setProgress(100);
        return;
      }
      if (dataStore.serverTime >= task.completeByMax) {
        setProgress(0);
        return;
      }
      setProgress(
        100 -
          ((dataStore.serverTime - task.completeByMin) * 100) /
            (task.completeByMax - task.completeByMin),
      );
      //eslint-disable-next-line
    }, [task.completeByMin, task.completeByMax, dataStore.serverTime]);

    return (
      <div
        className={generateHtmlClasses('order-info-bar', {
          _active: isActive,
          _late: isTaskCompleted ? etaDiffInMin > 0 : progress < 50,
          _earlier: isTaskCompleted ? etaDiffInMin <= 0 : progress >= 50,
          '_zero-min': etaDiffInMin === 0,
        })}
        onClick={handleOrderClick}
      >
        {etaDiffInMin > 0 && (
          <div className="order-info-bar__time time">
            <div className="time__value">{etaTime.value}</div>
            <div className="time__label">{etaTime.label}</div>
          </div>
        )}
        <div className="order-info-bar__info info">
          <div className="info__external-id">{order?.order.externalId || ''}</div>
          <div className="info__address">{address.name}</div>
        </div>
      </div>
    );
  },
);
