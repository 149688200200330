import { Order, OrderType } from 'api/types/Order';
import Parcels from 'components/FrameDetail/OrderDetails/Items/Parcels';
import Products from 'components/FrameDetail/OrderDetails/Items/Products';
import MobileEmptyBlock from 'components/MobileEmptyBlock';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { dataStore } from 'stores/DataStore';
import { mainStore } from 'stores/MainStore';
import { frameDetailStore } from '../store';
import Courier from './Items/Courier';
import Customer from './Items/Customer';
import { generateHtmlClasses } from 'utils/htmlClasses';

interface OrderDetailsProps {
  active: boolean;
}

const ParcelsOrProducts = ({ order }: { order: Order }): JSX.Element | null => {
  const parcels = frameDetailStore.tasks?.DROP_OFF?.parcels;
  if (!parcels || !parcels.length || !order?.orderType) return null;
  if (order.orderType === OrderType.FASHION) return <Products parcels={parcels} />;
  if (order.orderType === OrderType.GROCERY) return <Parcels parcels={parcels} />;
  return null;
};

export default observer(({ active }: OrderDetailsProps) => {
  const orderInfo = useMemo(() => {
    return (
      dataStore.getServerOrderById(mainStore.selectedOrder.id, mainStore.selectedOrder.jobId) ||
      dataStore.getLocalOrderById(mainStore.selectedOrder.id, mainStore.selectedOrder.jobId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainStore.selectedOrder.id, dataStore.activeTeam]);

  return (
    <div className={generateHtmlClasses('order-details', { _active: active })}>
      {frameDetailStore.tasks?.DROP_OFF && (
        <Customer
          contact={frameDetailStore.tasks.DROP_OFF.contact}
          address={frameDetailStore.tasks.DROP_OFF.address}
          order={orderInfo?.order}
        />
      )}
      {orderInfo?.courier && <Courier courier={orderInfo.courier} tasks={orderInfo.tasks} />}
      {orderInfo?.order && <ParcelsOrProducts order={orderInfo.order} />}
      <MobileEmptyBlock />
    </div>
  );
});
