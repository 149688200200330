export enum CompanyName {
  CityDrinks = 'citydrinks',
  Localee = 'localee',
  CircleK = 'circlek',
  OurKids = 'ourkids',
  Vilo = 'vilo',
  AvantGarde = 'avantgarde',
  RKBFruit = 'rkbfruit',
  OkieElectronics = 'okieelectronics',
  IndiMarket = 'indimarket',
  Oskuhus = 'oskuhus',
  HelenDoron = 'helendoron',
  CloudRetail = 'cloudretail',
  Jiffy = 'jiffy',
  Baqal = 'baqal',
  Swifft = 'swifft',
}

export type GtmConfig = { auth: string; preview: string };

export type CompanyConfig = {
  id: string;
  lang: string;
  langs: string[];
  name: CompanyName;
  defaultPhoneCode: string;
  phonePlaceholder: string;
  hostnames?: string[];
  hostname: string;
  logo: any;
  logoWidth: number;
};
