import React, { useEffect, useState, useRef, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { searchStore } from 'components/Search/store';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import Job from './Job';
import { mainStore } from 'stores/MainStore';
import { dataStore } from 'stores/DataStore';
import { authStore } from 'stores/AuthStore';
import { CourierStatus, VehicleType } from '../api/types/Courier';
import { useTranslation } from 'react-i18next';
import { generateHtmlClasses } from 'utils/htmlClasses';

interface CourierProps {
  id: string;
  status: CourierStatus;
  vehicleType: VehicleType;
  userName: string;
  index: number;
}

export default observer(({ id, status, vehicleType, userName, index }: CourierProps) => {
  const { t } = useTranslation();
  const [statusName, setStatusName] = useState('');
  const [vehicle, setVehicle] = useState('');
  const [isExpanded, setIsExpanded] = useState(authStore.expandedCourierIds.includes(id));
  const [isHover, setIsHover] = useState(false);
  const [isEditRouteMode, setIsEditRouteMode] = useState(false);
  const [isLoadingReorder, setIsLoadingReorder] = useState(false);
  const courierRef = useRef<HTMLDivElement | null>(null);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleCourierClick = () => {
    mainStore.setActiveDynamicFrame(null);
    mainStore.setIsMapDragged(false);
    searchStore.resetSearch();
    mainStore.setSelectedCourier({ id, source: 'sidebar' });
    if (!courierJobsList.length) {
      setIsExpanded(false);
      setIsEditRouteMode(false);
      return;
    }
    setIsExpanded(!isExpanded);
  };
  const handleShowCourierDetail = (e: React.MouseEvent) => {
    e.stopPropagation();
    mainStore.setSelectedCourier({ id, source: 'sidebar' });
    mainStore.setIsMapDragged(false);
    mainStore.setActiveDynamicFrame('courier');
  };

  const isDragDisabled = useCallback((): boolean => {
    return ![
      CourierStatus.OFFLINE,
      CourierStatus.ONLINE,
      CourierStatus.IDLE,
      CourierStatus.HEADING_TO_BASE,
    ].includes(status);
  }, [status]);

  const isDropDisabled = (): boolean => {
    if (status !== CourierStatus.IDLE && status !== CourierStatus.ASSIGNED_TO_JOB) return true;
    return (
      mainStore.dragItem.group.split('_')[0] === 'courierJobs' && mainStore.dragItem.type === 'job'
    );
  };
  const courierJobsList = dataStore.getCourierJobs(id);

  useEffect(() => {
    setStatusName(status ? CourierStatus[status]?.toLowerCase() || '' : '');
    return () => {
      setIsEditRouteMode(false);
    };
  }, [status]);

  useEffect(() => {
    setVehicle(VehicleType[vehicleType]?.toLowerCase() || '');
  }, [vehicleType]);

  useEffect(() => {
    if (
      !mainStore.selectedCourier.id ||
      mainStore.selectedCourier.id !== id ||
      mainStore.selectedCourier.source === 'sidebar' ||
      !courierRef ||
      !courierRef.current
    ) {
      return;
    }
    courierRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    if (courierJobsList.length) {
      const timeout = setTimeout(() => {
        setIsExpanded(true);
        clearTimeout(timeout);
      }, 200);
    }
    //eslint-disable-next-line
  }, [mainStore.selectedCourier.id, authStore.activeTeamId]);

  useEffect(() => {
    if (isExpanded) {
      authStore.addExpandedCourierId(id);
    } else {
      authStore.removeExpandedCourierId(id);
    }
  }, [isExpanded, id]);

  useEffect(() => {
    setIsExpanded(authStore.expandedCourierIds.includes(id));
    //eslint-disable-next-line
  }, [authStore.expandedCourierIds, id]);

  useEffect(() => {
    const job = dataStore.getJobListResponse?.find(
      (j) => j.job.id === mainStore.selectedOrder.jobId,
    );
    if (job?.courier?.courier.id === id) {
      setIsExpanded(true);
    }
    //eslint-disable-next-line
  }, [mainStore.selectedOrder]);

  useEffect(() => {
    if (!courierJobsList.length) setIsExpanded(false);
    setIsExpanded(false);
  }, [courierJobsList.length]);

  const handleMouseDown = useCallback(
    (event: React.MouseEvent) => {
      if (timerRef.current) clearTimeout(timerRef.current);
      if (!isDragDisabled()) {
        timerRef.current = setTimeout(() => {
          if (mainStore.activeDynamicFrame !== 'teamless') {
            mainStore.setActiveDynamicFrame(null);
            mainStore.setActiveDynamicFrame('teamless');
          }
        }, 75);
      }
      event.preventDefault();
    },
    [isDragDisabled],
  );

  const handleMouseUp = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
  };

  const handleChangeRoute = () => {
    setIsEditRouteMode(true);
  };

  const handleCancelChangeRoute = () => {
    setIsEditRouteMode(false);
    dataStore.resetReorder();
  };

  const handleChangePress = async () => {
    try {
      setIsLoadingReorder(true);
      dataStore.requestReorderJobOrders();
      setIsEditRouteMode(false);
      setIsLoadingReorder(false);
      mainStore.pushAlert('success', 'The route has been successfully changed');
    } catch (e) {
      mainStore.pushAlert('error', 'The route could not be changed. Try again');
      setIsLoadingReorder(true);
      console.log(e);
    }
  };

  return (
    <Draggable draggableId={id} key={id} index={index} isDragDisabled={isDragDisabled()}>
      {(provided) => {
        const handlers = {
          ...provided.dragHandleProps,
          ...provided.draggableProps,
          onMouseDown: isDragDisabled() ? undefined : handleMouseDown,
          onMouseUp: handleMouseUp(),
        };

        return (
          <div
            className={generateHtmlClasses('courier', {
              _expanded: isExpanded,
              _open: isHover,
              _blink:
                mainStore.selectedCourier.id === id &&
                mainStore.selectedCourier.source !== 'sidebar',
            })}
            ref={provided.innerRef}
            {...handlers}
          >
            <Droppable
              key={`courier_${id}`}
              droppableId={`courierJobs_${id}`}
              type="job"
              isDropDisabled={isDropDisabled()}
            >
              {(provided, snapshot) => {
                if (isHover !== snapshot.isDraggingOver) setIsHover(snapshot.isDraggingOver);

                return (
                  <div ref={provided.innerRef}>
                    <div className="courier-title" onClick={handleCourierClick} ref={courierRef}>
                      <div className={`icon icon-${vehicle} courier-icon _${statusName}`} />
                      <div className="courier-title__name">{userName}</div>
                      {statusName.length > 0 && (
                        <div className="courier-title__status">
                          {t(`courierStatus:${statusName}`)}
                        </div>
                      )}
                      <div className="courier-title__info" onClick={handleShowCourierDetail}>
                        <div />
                      </div>
                      {courierJobsList && courierJobsList.length > 0 && (
                        <div className="courier-title__arrow icon icon-chevron-up" />
                      )}
                    </div>
                    <div className="courier__body">
                      {courierJobsList && courierJobsList[0]?.orders.length > 1 && (
                        <div>
                          {!isEditRouteMode ? (
                            <div
                              style={{ paddingLeft: '9px' }}
                              className="courier__change_route"
                              onClick={handleChangeRoute}
                            >
                              {t('order:changeRoute')}
                            </div>
                          ) : (
                            <div className="courier__buttons_container">
                              <button
                                onClick={handleCancelChangeRoute}
                                className="button _secondary courier__button"
                              >
                                {t('common:cancel')}
                              </button>
                              <button
                                onClick={handleChangePress}
                                disabled={isLoadingReorder}
                                className="button  _primary courier__button"
                              >
                                {t('common:save')}
                              </button>
                            </div>
                          )}
                        </div>
                      )}

                      {isExpanded && provided.placeholder}

                      {!isExpanded && provided.placeholder}
                      {courierJobsList.map(({ job, orders }, index) => (
                        <Job
                          isEditable={isEditRouteMode}
                          jobId={job.id}
                          isAssigned
                          orders={orders || []}
                          handleToggleExpanding={setIsExpanded}
                          isExpanded={isExpanded}
                          index={index}
                          key={job.id}
                        />
                      ))}
                      {isExpanded && provided.placeholder}
                    </div>
                  </div>
                );
              }}
            </Droppable>
          </div>
        );
      }}
    </Draggable>
  );
});
