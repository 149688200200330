import { Address } from 'api/types/Address';
import { ITaskWithRels } from './Task';

export enum ForceAction {
  FAIL = 'FAIL',
  CANCEL = 'CANCEL',
  COMPLETE = 'COMPLETE',
}
export enum OrderStatus {
  NEW = 'NEW',
  BATCHED = 'BATCHED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
}

export enum OrderType {
  GROCERY = 'GROCERY',
  FASHION = 'FASHION',
}

export interface OrderLocation {
  x: number;
  y: number;
}

export enum OrderPaymentMethod {
  CARD = 'CARD',
  CASH = 'CASH',
}

export interface OrderPayment {
  paymentMethod: OrderPaymentMethod;
  paidTotal: number;
}

export interface Order {
  id: string;
  externalId: string;
  contactId: string;
  startBy: number;
  deliverByMin: number;
  deliverByMax: number;
  status: OrderStatus;
  note: string;
  createdAt: number;
  updatedAt: number;
  deletedAt: number;
  location?: OrderLocation;
  isBatchProcessed: boolean;

  isPicked: boolean;
  totalItemsCount?: number;
  totalParcelsCount?: number;
  eta: number;
  distance: number;

  forceAction: Nullable<ForceAction>;
  forceActionActor: Nullable<string>;
  seller: string;

  addressId: string;

  batchOrderBlackList?: string;

  orderType: OrderType;

  companyId: string;

  slotDeliveryId: Nullable<string>;

  startAfter: number;
  ordersCount: number;
  orderDisplayRank?: number;

  pickingAt: number;

  customerCash: number;
  customerChange: number;

  deliveryZoneId?: string;
  deliveryZoneName?: string;

  address?: Address;
  payment: OrderPayment;
}

export interface IOrderMapInfo {
  order: Order;
  tasks: ITaskWithRels[];
}
