import { observer } from 'mobx-react-lite';
import { authStore } from 'stores/AuthStore';
interface CompanyLogoProps {
  className: string;
}

export default observer(({ className }: CompanyLogoProps) => {
  const logoImage = authStore.companyConfig?.logo;
  const logoWidth = authStore.companyConfig?.logoWidth;

  return <img className={className} src={logoImage} alt="" style={{ width: `${logoWidth}px` }} />;
});
