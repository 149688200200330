import { CourierRequests } from 'api/Courier';
import { DashboardRequests } from 'api/Dashboard';
import type { CreateCourierDto } from 'api/dto/CreateCourierDto';
import type { CreateTeamDto } from 'api/dto/CreateTeamDto';
import type { EditTeamDto } from 'api/dto/EditTeamDto';
import type { UpdateCourierDto } from 'api/dto/UpdateCourierDto';
import { JobRequests } from 'api/Job';
import { OrderRequests } from 'api/Order';
import { type CreateTeamError, CreateTeamErrorMessage } from 'api/response/CreateTeamResponse';
import type {
  CourierWithRel,
  GetJobListResponse,
  JobSlotListResponse,
  OrderWithRel,
  TaskWithRel,
} from 'api/response/GetJobListResponse';
import { TeamRequests } from 'api/Team';
import { type Courier, CourierStatus } from 'api/types/Courier';
import { JobState, type SlotJob } from 'api/types/Job';
import type { Order } from 'api/types/Order';
import { TaskKind, TaskState } from 'api/types/Task';
import { UserRequests } from 'api/User';
import { WarehouseRequests } from 'api/Warehouse';
import { searchStore } from 'components/Search/store';
import type { SelectItem } from 'components/Select';
import { DEFAULT_TIME_ZONE, MAX_REQUEST_PAGE_SIZE, ORDER_COMPLETE_STATUSES } from 'config';
import type { GeoJsonProperties } from 'geojson';
import { makeAutoObservable, observable, toJS } from 'mobx';
import {
  JobsGetListRespV2,
  tasks,
} from 'proto/build/js/last-mile/dispatcher/public/admin/v2/jobs_get_list';
import { WarehouseWithTeamsRel } from 'proto/build/js/last-mile/dispatcher/public/admin/warehouse_with_teams';
import Supercluster from 'supercluster';
import { DashboardTeamDto, DashboardTeamlessCourierDto } from '../api/dto/DashboardInfoDto';
import { IDeliveryZoneDto } from '../api/dto/DeliveryZoneDto';
import { GetTripDto } from '../api/dto/GetTripDto';
import { IUserWithRoles } from '../api/dto/GetUserByTeamDto';
import { PolygonItem } from '../api/types/Polygon';
import { IRole } from '../api/types/Role';
import { ITripPoint } from '../api/types/TripPoint';
import { Warehouse } from '../api/types/Warehouse';
import PinAssigned from '../assets/img/pin_assigned.svg';
import PinCanceled from '../assets/img/pin_cancelled.svg';
import PinInProgress from '../assets/img/pin_in_progress.svg';
import PinCompleted from '../assets/img/pin_successful.svg';
import PinUnassigned from '../assets/img/pin_unassigned.svg';
import PinFailed from '../assets/img/pin_unsuccessful.svg';
import { frameDetailStore } from '../components/FrameDetail/store';
import { authStore } from './AuthStore';
import { mainStore } from './MainStore';
import i18n from 'i18next';
import { sortAlphabet, sortNumbers } from 'utils/sorting';

export type DeliveryZoneItem = { polygons: Map<string, PolygonItem[][]> };

type DeliveryZones = Record<string, DeliveryZoneItem>;

export type CourierPoint = Supercluster.PointFeature<
  GeoJsonProperties & {
    courierId: string;
    courier: DashboardTeamlessCourierDto;
  }
>;

export type TaskPoint = Supercluster.PointFeature<
  GeoJsonProperties & {
    jobId: string;
    orderId: string;
    orderExternalId: string;
    taskId: string;
    courierId?: string;
    task: TaskWithRel;
  }
>;

export type JobTrip = {
  jobId: string;
  trip: ITripPoint[];
};

export type OrderInfo = {
  order: Order;
  tasks: TaskWithRel[];
  courier: CourierWithRel | null;
} | null;

type CurrentEditableJobData = {
  orders: OrderWithRel[];
  jobId: string;
} | null;

class DataStore {
  teams: DashboardTeamDto[] | null = null;
  warehouses: Warehouse[] | null = null;
  couriers: Courier[] | null = null;
  teamlessCouriersDuty: DashboardTeamlessCourierDto[] | null = null;
  teamlessCouriersOffline: DashboardTeamlessCourierDto[] | null = null;
  getJobListResponse: GetJobListResponse[] | null = null;
  slotJobListResponse: JobSlotListResponse | null = null;
  deliveryZones: DeliveryZones | null = null;
  serverTime = 0;
  jobTrips: JobTrip[] | null = null;
  currentEditableJobData: CurrentEditableJobData = null;
  fullJobsList = observable.map(new Map<string, GetJobListResponse>());
  slotsJobsList = observable.map(new Map<string, GetJobListResponse>());
  constructor() {
    makeAutoObservable(this);
  }

  // Getters
  get unassignedJobs(): GetJobListResponse[] {
    if (!this.getJobListResponse) return [];
    return this.getJobListResponse
      .filter((job) => {
        if (job.courier) return false;
        if (!authStore.activeTeamId) return true;
        if (!job.orders || !job.orders.length) return false;
        // don't show jobs with finished tasks
        if (ORDER_COMPLETE_STATUSES.includes(job.orders[0].order.status)) return false;
        return job.orders[0].tasks[0]?.task.teamId === authStore.activeTeamId;
      })
      .sort(({ job: a }, { job: b }) => sortNumbers(a.createdAt, b.createdAt));
  }

  get unassignedJobsSlots() {
    if (!this.slotJobListResponse) return {};

    const filteredData: JobSlotListResponse = {};

    for (const timestamp in this.slotJobListResponse) {
      if (this.slotJobListResponse?.[timestamp]) {
        const timeSlots = this.slotJobListResponse[timestamp];

        for (const timeSlotKey in timeSlots) {
          if (timeSlots?.[timeSlotKey]) {
            const timeSlot = timeSlots[timeSlotKey];
            const filteredOrders = [];

            for (const order of timeSlot.orders) {
              for (const task of order.tasks) {
                if (task.task.teamId === authStore.activeTeamId) {
                  filteredOrders.push(order);
                  break;
                }
              }
            }

            if (filteredOrders.length > 0) {
              if (!filteredData[timestamp]) {
                filteredData[timestamp] = {};
              }
              filteredData[timestamp][timeSlotKey] = {
                orders: filteredOrders,
                courier: timeSlot.courier,
                job: timeSlot.job,
              };
            }
          }
        }
      }
    }

    return filteredData;
  }

  get unassignedCount(): number {
    return this.unassignedJobs.length;
  }

  get unassignedOrdersCount(): number {
    return this.unassignedJobs.reduce((count: number, job) => {
      return count + job.orders.length;
    }, 0);
  }

  get teamlessCouriersCount(): number {
    return (this.teamlessCouriersDuty?.length || 0) + (this.teamlessCouriersOffline?.length || 0);
  }

  get teamsCouriersCount(): number {
    if (!this.teams) return 0;
    return this.teams.reduce((sum, team) => team.couriers.length + sum, 0);
  }

  get activeTeam(): DashboardTeamDto | null {
    if (!authStore.activeTeamId || !this.teams || !this.teams.length) return null;
    return this.teams.find(({ team }) => team.id === authStore.activeTeamId) || null;
  }

  get activeTeamJobs(): JobsGetListRespV2.JobV2[] | GetJobListResponse[] {
    if (!this.getJobListResponse || !this.getJobListResponse.length) return [];

    return this.getJobListResponse.filter((job) => {
      return job.orders.some((order) =>
        order.tasks.some((task) => task.task.teamId === authStore.activeTeamId),
      );
    });
  }

  get activeWarehouseExternalUid(): string {
    const { warehouse } = this.activeTeam || {};
    if (!warehouse) return '';
    return warehouse.warehouse.externalUid || warehouse.warehouse.id;
  }

  get activeWarehouseCoord(): { lat: number; lng: number } | null {
    const { warehouse } = this.activeTeam || {};
    if (!warehouse) return null;
    return { lat: warehouse.address.latitude, lng: warehouse.address.longitude };
  }

  get activeCouriers(): DashboardTeamlessCourierDto[] {
    return (this.activeTeam?.couriers || []).filter(
      ({ courier }) => courier.status !== CourierStatus.OFFLINE,
    );
  }

  get couriersPoints(): CourierPoint[] {
    if (mainStore.activeDynamicFrame === 'courier') {
      const courier =
        this.teamlessCouriersOffline?.find(
          (courier) => courier.courier.id === mainStore.selectedCourier.id,
        ) ||
        this.teamlessCouriersDuty?.find(
          (courier) => courier.courier.id === mainStore.selectedCourier.id,
        );
      if (courier) {
        return [
          {
            type: 'Feature',
            properties: {
              cluster: false,
              category: 'Courier',
              courierId: courier.courier.id,
              courier,
            },
            geometry: {
              type: 'Point',
              coordinates: [courier.courier.longitude, courier.courier.latitude],
            },
          },
        ];
      }
    }
    return (
      mainStore.activeDynamicFrame === 'teamless'
        ? this.teamlessCouriersDuty || []
        : this.activeCouriers
    ).map((courier) => ({
      type: 'Feature',
      properties: {
        cluster: false,
        category: 'Courier',
        courierId: courier.courier.id,
        courier,
      },
      geometry: {
        type: 'Point',
        coordinates: [courier.courier.longitude, courier.courier.latitude],
      },
    }));
  }

  get tasksPoints(): TaskPoint[] {
    if (!this.getJobListResponse || !this.activeTeam) return [];
    const jobs: GetJobListResponse[] = [];

    this.fullJobsList.forEach((job) => {
      if (!authStore.activeTeamId) return true;
      if (!job.orders || !job.orders.length) return false;
      if (job.orders[0].tasks[0]?.task.teamId === authStore.activeTeamId) {
        jobs.push(job);
      }
    });
    /*this.slotsJobsList.forEach((job) => {
      if (!authStore.activeTeamId) return true;
      if (!job.orders || !job.orders.length) return false;
      if (job.orders[0].tasks[0]?.task.teamId === authStore.activeTeamId) {
        jobs.push(job);
      }
    });*/

    const points: TaskPoint[] = [];
    jobs.forEach(({ job, courier, orders }) => {
      orders.forEach(({ order, tasks }) => {
        tasks.forEach((task) => {
          if (!task.task.kind) return;
          if (task.task.kind === TaskKind.DROP_OFF || task.task.kind === TaskKind.RETURN) {
            let coords = [task.address.longitude, task.address.latitude];
            if (task.task.kind === TaskKind.RETURN) {
              const warehouse = this.teams?.find(
                (team) => team.warehouse.warehouse.id === task.task.warehouseId,
              );
              if (warehouse) {
                coords = [
                  warehouse.warehouse.address.longitude,
                  warehouse.warehouse.address.latitude,
                ];
              }
            }
            points.push({
              type: 'Feature',
              properties: {
                cluster: false,
                category: 'Task',
                jobId: job.id,
                orderId: order.id,
                orderExternalId: order.externalId,
                taskId: task.task.id,
                courierId: courier?.courier.id || undefined,
                task,
              },
              geometry: {
                type: 'Point',
                coordinates: coords,
              },
            });
          }
        });
      });
    });

    return points;
  }

  get teamList(): SelectItem[] {
    if (!this.teams || !this.teams.length) return [];
    return this.teams.map(({ team }) => ({ value: team.id, text: team.name, rawText: team.name }));
  }

  get warehouseList(): SelectItem[] {
    if (!this.warehouses || !this.warehouses.length) return [];
    return this.warehouses.map((warehouse) => ({
      value: warehouse.id,
      text: `<div class='d-flex'><div class='w-100p text-truncate'>${warehouse.name}</div><div class='ml-10'>${warehouse.externalId}</div></div>`,
      rawText: warehouse.name,
    }));
  }

  get courierList(): SelectItem[] {
    if (!this.couriers || !this.couriers.length) return [];
    return this.couriers.map((courier) => ({
      value: courier.id,
      text: courier.name || '',
      rawText: courier.name || '',
    }));
  }

  // Setters
  setTeams(val: DashboardTeamDto[] | null) {
    this.teams = val;
  }

  clearJobsList() {
    this.fullJobsList.clear();
    this.slotsJobsList.clear();
  }

  setTeamlessCouriersDuty(val: DashboardTeamlessCourierDto[] | null) {
    this.teamlessCouriersDuty = val;
  }

  setTeamlessCouriersOffline(val: DashboardTeamlessCourierDto[] | null) {
    this.teamlessCouriersOffline = val;
  }

  setJobListResponse(val: GetJobListResponse[] | null) {
    this.getJobListResponse =
      val?.map((item) => {
        if (this.currentEditableJobData && item.job.id === this.currentEditableJobData.jobId) {
          return {
            ...item,
            orders: this.currentEditableJobData.orders,
          };
        }
        return item;
      }) || null;
  }

  setSlotJobListResponse(val: JobSlotListResponse | null) {
    this.slotJobListResponse = val;
  }

  setFullJobListResponse(val: GetJobListResponse[] | null, slots?: boolean) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    for (const [key] of this.fullJobsList.entries()) {
      if (!val?.some((item) => item.job.id === key)) {
        if (!slots) {
          this.fullJobsList.delete(key);
        } else {
          this.slotsJobsList.delete(key);
        }
      }
    }

    val?.forEach((entity) => {
      if (!slots) {
        this.fullJobsList.set(entity.job.id, entity);
      } else {
        this.slotsJobsList.set(entity.job.id, entity);
      }
    });
  }

  setJobsTrips(val: JobTrip[]) {
    this.jobTrips = val;
  }

  setDeliveryZones(val: IDeliveryZoneDto[]) {
    const deliveryZones: DeliveryZones = {};
    val
      .sort(({ priority: a }, { priority: b }) => sortNumbers(a, b))
      .forEach(({ warehouse_id, points, color }, index) => {
        const polygon = points.map(
          ({ latitude, longitude }) => ({ lat: latitude, lng: longitude } as PolygonItem),
        );

        if (!deliveryZones[warehouse_id]) {
          deliveryZones[warehouse_id] = {
            polygons: new Map(),
          };
        }
        const polygons = deliveryZones[warehouse_id].polygons.get(color) || [];
        polygons.push(polygon);
        deliveryZones[warehouse_id].polygons.set(color, polygons);
      });
    this.deliveryZones = Object.keys(deliveryZones).length ? deliveryZones : null;
  }

  setServerTime(val: number) {
    this.serverTime = val;
  }

  setWarehouses(val: Warehouse[] | null) {
    this.warehouses = val;
  }

  setCouriers(val: Courier[] | null) {
    this.couriers = val;
  }

  // Actions
  getFilteredStates(): JobState[] {
    const jobStates = Object.keys(JobState) as JobState[];
    return jobStates.filter((key) => authStore.jobStateFilter[key]);
  }

  checkActiveTeamId(teams: DashboardTeamDto[]) {
    if (!teams.length) {
      authStore.setActiveTeamId('');
      return;
    }
    if (mainStore.selectedOrder && searchStore.searchValue) {
      return;
    }
    if (!authStore.activeTeamId) {
      authStore.setActiveTeamId(teams[0].team.id);
    } else if (!this.teamExistsLocally(authStore.activeTeamId)) {
      authStore.setActiveTeamId(teams[0].team.id);
    }
  }

  getCourierJobs(courierId: string): GetJobListResponse[] {
    if (!this.getJobListResponse || !this.getJobListResponse.length) return [];
    return this.getJobListResponse.filter(
      ({ courier, orders }) => courier?.courier.id === courierId && orders.length,
    );
  }

  getCourierById(courierId: string): DashboardTeamlessCourierDto | null {
    const findCourierById = ({ courier }: { courier: DashboardTeamlessCourierDto['courier'] }) =>
      courier.id === courierId;
    return (
      (this.activeTeam?.couriers || []).find(findCourierById) ||
      this.teamlessCouriersDuty?.find(findCourierById) ||
      this.teamlessCouriersOffline?.find(findCourierById) ||
      null
    );
  }

  getTaskStatusIconV2(status: TaskState): string {
    const iconList: Record<TaskState, string> = {
      UNASSIGNED: PinUnassigned,
      ASSIGNED_TO_JOB: PinAssigned,
      ON_THE_WAY: PinInProgress,
      ON_POINT: PinInProgress,
      COMPLETED: PinCompleted,
      CANCELED: PinCanceled,
      FAILED: PinFailed,
    };
    return iconList[TaskState[status] as keyof typeof TaskState];
  }

  getTaskStatusIcon(status: tasks.State): string {
    const iconList: Record<keyof typeof tasks.State, string> = {
      UNASSIGNED: PinUnassigned,
      ASSIGNED_TO_JOB: PinAssigned,
      ON_THE_WAY: PinInProgress,
      ON_POINT: PinInProgress,
      COMPLETED: PinCompleted,
      CANCELED: PinCanceled,
      FAILED: PinFailed,
    };
    return iconList[tasks.State[status] as keyof typeof tasks.State];
  }

  getServerOrderById(orderId: string, jobId: string): OrderInfo {
    let result: OrderInfo = null;

    searchStore.searchResponse?.jobs.find(({ job, orders, courier }) => {
      if (jobId && job.id !== jobId) return false;
      return orders.find((order) => {
        if (order.order.id === orderId) {
          result = { ...toJS(order), courier: toJS(courier) || null };
          return true;
        }
        return false;
      });
    });
    return result;
  }

  getLocalOrderById(orderId: string, jobId?: string): OrderInfo {
    if (!this.getJobListResponse || !this.getJobListResponse.length) return null;
    let result: OrderInfo = null;

    const entry = this.fullJobsList.get(jobId);
    if (entry) {
      const order = entry.orders.find((order) => order.order.id === orderId);
      if (order) {
        result = { ...toJS(order), courier: toJS(entry.courier) || null };
      }
    }
    if (result) return result;
    this.slotsJobsList.forEach((entry) => {
      if (jobId && entry.job.id !== jobId) return false;
      entry.orders.find((order) => {
        if (order.order.id === orderId) {
          result = { ...toJS(order), courier: toJS(entry.courier) || null };
          return true;
        }
        return false;
      });
    });
    return result;
  }

  isOrderUnassigned(orderId: string): boolean {
    return this.unassignedJobs.some((job) => job.orders.some((o) => o.order.id === orderId));
  }

  teamExistsLocally(teamId: string): boolean {
    return !!this.teams?.some((team) => team.team.id === teamId);
  }

  orderExistsLocally(orderId: string): boolean {
    return !!this.getJobListResponse?.some((item) =>
      item.orders.some((order) => order.order.id === orderId),
    );
  }

  getRoleName(role: IRole): string {
    const roleNameUpper = IRole[role].toUpperCase();
    const roleNameLower = IRole[role].toLowerCase();
    return roleNameUpper[0] + roleNameLower.slice(1, roleNameLower.length);
  }

  // Requests API
  async requestDashboardInfo(): Promise<void> {
    try {
      const { data } = await DashboardRequests.getInfo();
      if (data) {
        const { teams, teamlessCouriers, serverTime } = data;
        const normalizedServerTime = Math.round(new Date(serverTime || 0).getTime() / 1000);
        this.setServerTime(normalizedServerTime);
        this.checkActiveTeamId(teams || []);
        (teams || []).forEach(
          ({ couriers }, i) =>
            (teams[i].couriers = couriers.sort(({ user: a }, { user: b }) =>
              sortAlphabet(a.user.name, b.user.name),
            )),
        );
        teams.sort((a, b) => a.team.name.localeCompare(b.team.name));
        this.setTeams(teams);
        const teamlessCouriersDuty: DashboardTeamlessCourierDto[] = [];
        const teamlessCouriersOffline: DashboardTeamlessCourierDto[] = [];
        (teamlessCouriers || [])
          .sort(({ user: a }, { user: b }) => sortAlphabet(a.user.name, b.user.name))
          .forEach((courier) => {
            if (courier.courier.status === CourierStatus.OFFLINE) {
              teamlessCouriersOffline.push(courier);
            } else {
              teamlessCouriersDuty.push(courier);
            }
          });
        this.setTeamlessCouriersDuty(teamlessCouriersDuty);
        this.setTeamlessCouriersOffline(teamlessCouriersOffline);
      } else {
        this.setTeams([]);
        this.setTeamlessCouriersDuty([]);
        this.setTeamlessCouriersOffline([]);
      }
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'requestDashboardInfo');
      return Promise.reject();
    }
  }

  async requestJobList(): Promise<void> {
    try {
      const allowedStates = this.getFilteredStates();
      if (!allowedStates.length) {
        this.setJobListResponse([]);
        return;
      }
      const selectedWarehouse = this.activeTeam?.warehouse.warehouse.externalId;
      const options: any = {
        allowedStates,
        page: { size: MAX_REQUEST_PAGE_SIZE, current: 1 },
      };
      if (selectedWarehouse) {
        options.warehouses = [selectedWarehouse];
      }
      const resp = await DashboardRequests.getJobList(options);

      if (resp) {
        this.setFullJobListResponse(resp.data);
        this.setJobListResponse(resp.data);
        if (mainStore.activeDynamicFrame === 'detail') {
          frameDetailStore.updateOrderInfo();
        }
      } else {
        this.setJobListResponse([]);
      }
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'requestJobList');
      return Promise.reject();
    }
  }

  async requestSlotJobList(): Promise<void> {
    try {
      const allowedStates = this.getFilteredStates();
      if (!allowedStates.length) {
        this.setSlotJobListResponse({});
        return;
      }
      const resp = await DashboardRequests.getSlotJobList({
        allowedStates,
        timeZone: this.getCurrentTeamTimezone(),
        page: { size: MAX_REQUEST_PAGE_SIZE, current: 1 },
      });

      let newData = {};
      const objectKeys = Object.keys(resp.data);
      const allJobs = Object.values(resp.data).flat();

      this.setFullJobListResponse(allJobs, true);

      Object.values(resp.data).forEach((entity, index) => {
        const groupedData: SlotJob = {};
        entity.forEach((item) => {
          item.orders.forEach((orderData) => {
            const order = orderData.order;
            const deliverByMin = order.deliverByMin;
            const deliverByMax = order.deliverByMax;
            const key = `${this.getEta(deliverByMin)}-${this.getEta(deliverByMax)}`;

            if (!groupedData[key]) {
              groupedData[key] = {
                orders: [],
                courier: item.courier,
                job: item.job,
              };
            }

            groupedData[key].orders.push(orderData);
          });
        });

        newData = {
          ...newData,
          [objectKeys[index]]: groupedData,
        };
      });

      if (resp) {
        this.setSlotJobListResponse(newData);
        if (mainStore.activeDynamicFrame === 'detail') {
          frameDetailStore.updateOrderInfo();
        }
      } else {
        this.setSlotJobListResponse({});
      }
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'requestSlotJobList');
      return Promise.reject();
    }
  }

  async requestWarehouseWithTeams(warehouseId: string): Promise<WarehouseWithTeamsRel | null> {
    try {
      const resp = await WarehouseRequests.getOne(warehouseId);
      if (resp.data) {
        return resp.data;
      } else {
        return null;
      }
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'getWarehouseWithTeams');
      return null;
    }
  }

  async requestUsersBySearchString(searchStr: string): Promise<IUserWithRoles[] | null> {
    try {
      const searchEncoded = encodeURIComponent(searchStr.toLowerCase());
      const resp = await UserRequests.getBySearchString(searchEncoded);
      if (resp.data) {
        return resp.data;
      } else {
        return null;
      }
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'requestUsersBySearchString');
      return null;
    }
  }

  async requestTeamUsers(teamId: string): Promise<IUserWithRoles[] | null> {
    try {
      const resp = await UserRequests.getByTeam(teamId);
      if (resp.data) {
        return resp.data;
      } else {
        return null;
      }
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'getWarehouseWithTeams');
      return null;
    }
  }

  async requestWarehouseList(): Promise<Warehouse[]> {
    try {
      const { data = [] } = await WarehouseRequests.getInfo();
      return data;
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'requestWarehouseList');
      return Promise.reject();
    }
  }

  async requestWmsConnectorWarehouses(): Promise<Warehouse[]> {
    try {
      const { data = [] } = await WarehouseRequests.getWarehouses();
      return data;
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'requestWmsConnectorWarehouses');
      return Promise.reject();
    }
  }

  async getWarehouseList(): Promise<void> {
    try {
      const warehouses = await this.requestWarehouseList();
      const wmsWarehouses = await this.requestWmsConnectorWarehouses();

      if (!warehouses?.length || !wmsWarehouses?.length) {
        return;
      }

      const warehousesWithTimezone = warehouses?.map((warehouse) => {
        return {
          ...warehouse,
          timezone:
            wmsWarehouses.find((w) => w.id === warehouse.externalUid)?.timezone ||
            DEFAULT_TIME_ZONE,
        };
      });

      this.setWarehouses(warehousesWithTimezone);
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'getWarehouseList');
      return Promise.reject();
    }
  }

  async requestCourierList(): Promise<void> {
    try {
      const resp = await CourierRequests.getList();
      const couriers = resp.data;
      if (couriers) {
        this.setCouriers(couriers);
      } else {
        this.setCouriers([]);
      }
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'requestCourierList');
      return Promise.reject();
    }
  }

  async requestDeliveryZones(): Promise<void> {
    if (!this.teams || !this.teams.length) return;
    try {
      const response = await DashboardRequests.getDeliveryZones();
      const { data } = response;
      this.setDeliveryZones(data || []);
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'requestDeliveryZones');
      return Promise.reject();
    }
  }

  async courierForceOffline(courierId: string): Promise<void> {
    if (!courierId) return Promise.reject();
    try {
      const resp = await CourierRequests.forceOffline({ id: courierId });
      if (resp.data.status) {
        await this.requestDashboardInfo();
      } else {
        mainStore.pushAlert('error', resp.data.message);
      }
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'courierForceOffline');
      return Promise.reject();
    }
  }

  async orderForceComplete(orderId: string): Promise<void> {
    if (!orderId) return Promise.reject();
    try {
      await OrderRequests.forceComplete({ orderId });
      await this.requestJobList();
      await searchStore.searchOrders(searchStore.searchValue);
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'orderForceComplete');
      return Promise.reject();
    }
  }

  async orderForceFail(orderId: string): Promise<void> {
    if (!orderId) return Promise.reject();
    try {
      await OrderRequests.forceFail({ orderId });
      await this.requestJobList();
      await searchStore.searchOrders(searchStore.searchValue);
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'orderForceFail');
      return Promise.reject();
    }
  }

  async createCourier(data: CreateCourierDto): Promise<Courier | null> {
    try {
      const resp = await CourierRequests.create(data);
      if (resp.data) {
        this.requestDashboardInfo().catch(() => void 0);
        mainStore.pushAlert('success', i18n.t('successPhrases:newCourierSuccessfullyCreated'));
        return resp.data;
      }

      mainStore.pushAlert('error', i18n.t('errorPhrases:somethingWentWrong'));
      return null;
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'createCourier');
      return Promise.reject();
    }
  }
  /*
  async newCourier(data: { name: string; phone: string }): Promise<string | null> {
    try {
      const encoded = CourierCreateReqV2.encode(CourierCreateReqV2.create(data)).finish();
      const request =
        mainStore.getJiffyMessageType<JiffyMessageBody.Type.DISPATCHER__REQUEST__ADMIN__COURIER_CREATE_V2>(
          encoded,
          JiffyMessageBody.Type.DISPATCHER__REQUEST__ADMIN__COURIER_CREATE_V2,
        );
      const resp = await CourierRequests.newCourier(request);
      await this.requestDashboardInfo();
      mainStore.pushAlert('success', i18n.t('successPhrases:newCourierSuccessfullyCreated'));

      const userExternalId = CourierCreateRespV3.decode(resp.body.data).externalId;
      return userExternalId || null;
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'newCourier');
      return Promise.reject();
    }
  }
*/

  async updateCourier(courierId: string, data: UpdateCourierDto): Promise<Courier | null> {
    try {
      const resp = await CourierRequests.update(courierId, data);
      if (resp.data) {
        mainStore.pushAlert('success', i18n.t('successPhrases:courierSuccessfullyUpdated'));
        return resp.data;
      }
      return null;
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'updateCourier');
      return Promise.reject();
    }
  }

  async createTeam(data: CreateTeamDto): Promise<void> {
    try {
      const newTeam = await TeamRequests.createTeam(data);
      if (newTeam.data.name === data.name) {
        mainStore.pushAlert('success', i18n.t('successPhrases:newTeamSuccessfullyCreated'));
        await this.requestDashboardInfo();
      } else {
        mainStore.pushAlert('error', i18n.t('errorPhrases:errorOccurredWhileCreatingTeam'));
      }
    } catch (error) {
      const errorFields = error?.response?.data?.error?.fields;
      const errorCode = errorFields[Object.keys(errorFields)[0]][0]?.code as CreateTeamError;
      error && console.error(error);
      mainStore.errorHandler(error, 'createTeam', CreateTeamErrorMessage[errorCode]);
      return Promise.reject();
    }
  }

  async deleteTeam(teamId: string): Promise<void> {
    try {
      const { data: deletedTeam } = await TeamRequests.deleteTeam(teamId);
      if (deletedTeam.id === teamId && deletedTeam.status === 'DELETED') {
        mainStore.pushAlert('success', i18n.t('successPhrases:teamSuccessfullyDeleted'));
        await this.requestDashboardInfo();
      } else {
        mainStore.pushAlert('error', i18n.t('errorPhrases:errorOccurredWhileDeletingTeam'));
      }
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'deleteTeam');
      return Promise.reject();
    }
  }

  async editTeam(teamId: string, data: EditTeamDto): Promise<void> {
    try {
      const { data: updatedTeam } = await TeamRequests.editTeam(teamId, data);
      if (updatedTeam.id === teamId && updatedTeam.status === 'UPDATED') {
        mainStore.pushAlert('success', i18n.t('successPhrases:teamSuccessfullyUpdated'));
        await this.requestDashboardInfo();
      } else {
        mainStore.pushAlert('error', i18n.t('errorPhrases:errorOccurredWhileUpdatingTeam'));
      }
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'editTeam');
      return Promise.reject();
    }
  }

  async courierUnassignTeam(courierId: string): Promise<void> {
    if (!courierId || !authStore.activeTeamId) return Promise.reject();
    try {
      await CourierRequests.unassignTeam({
        userId: courierId,
        teamId: authStore.activeTeamId,
      });
      await this.requestDashboardInfo();
      mainStore.pushAlert('success', i18n.t('successPhrases:courierUnassignedSuccessful'));
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'courierUnassignTeam');
      return Promise.reject();
    }
  }

  async courierAssignTeam(courierId: string): Promise<void> {
    if (!courierId || !authStore.activeTeamId) return Promise.reject();
    try {
      const resp = await TeamRequests.assignUser({
        userId: courierId,
        teamId: authStore.activeTeamId,
      });
      if (resp.data.status === 'ASSIGNED') {
        await this.requestDashboardInfo();
        mainStore.pushAlert('success', i18n.t('successPhrases:courierAssignedSuccessful'));
      } else {
        mainStore.pushAlert('error', i18n.t('errorPhrases:somethingWentWrong'));
      }
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'courierAssignTeam');
      return Promise.reject();
    }
  }

  async jobUnassignCourier({
    jobId,
    courierId,
  }: {
    jobId: string;
    courierId: string;
  }): Promise<void> {
    if (!jobId || !courierId) return Promise.reject();
    try {
      await OrderRequests.unassignCourier({ jobId, courierId });
      await this.requestJobList();
      mainStore.pushAlert('success', i18n.t('successPhrases:jobUnassignedSuccessful'));
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'jobUnassignCourier');
      return Promise.reject();
    }
  }

  async jobAssignCourier(requestData: { jobId: string; courierId: string }) {
    if (!requestData.jobId || !requestData.courierId) return Promise.reject();
    try {
      await OrderRequests.assignCourier(requestData);
      await this.requestJobList();
      mainStore.pushAlert('success', i18n.t('successPhrases:jobAssignedSuccessful'));
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'jobAssignCourier');
      return Promise.reject();
    }
  }

  async addMemberToTeam(teamId: string, userId: string): Promise<void> {
    if (!userId || !teamId) return Promise.reject();

    try {
      await TeamRequests.assignUser({ teamId, userId });
      mainStore.pushAlert('success', i18n.t('successPhrases:membersAttachedToTeamSuccessful'));
      return Promise.resolve();
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'addMembersToTeam');
      return Promise.reject();
    }
  }

  async deleteMemberFromTeam(teamId: string, userId: string): Promise<void> {
    if (!userId || !teamId) return Promise.reject();

    try {
      await TeamRequests.detachUser({ teamId, userId });
      mainStore.pushAlert('success', i18n.t('successPhrases:memberDeletedFromTeamSuccessful'));
      return Promise.resolve();
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'deleteMemberFromTeam');
      return Promise.reject();
    }
  }

  async splitOrder(orderId: string): Promise<void> {
    if (!orderId) return Promise.reject();
    try {
      await OrderRequests.split({ orderId });
      await this.requestJobList();
      mainStore.pushAlert('success', i18n.t('successPhrases:orderUnassignedSuccessful'));
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'jobUnassignOrder');
      return Promise.reject();
    }
  }

  async resetReorder() {
    this.currentEditableJobData = null;
    await this.requestJobList();
  }

  async reorderJobOrders(jobId: string, startIndex: number, endIndex: number) {
    this.getJobListResponse =
      this.getJobListResponse?.map((item) => {
        if (item.job.id === jobId) {
          const result = item.orders;
          const [removed] = result.splice(startIndex, 1);
          result.splice(endIndex, 0, removed);

          this.currentEditableJobData = {
            jobId,
            orders: item.orders,
          };

          return {
            ...item,
            orders: result,
          };
        }
        return item;
      }) || null;
  }

  async requestReorderJobOrders() {
    try {
      if (this.currentEditableJobData) {
        const requestData = this.currentEditableJobData?.orders.map((item, index) => ({
          id: item.order.id,
          indexNumber: index + 1,
        }));
        await OrderRequests.reorderJobOrders(requestData);
        await this.requestJobList();
        this.currentEditableJobData = null;
        mainStore.pushAlert('success', 'The route has been successfully changed');
      }
    } catch (error) {
      error && console.error(error);
      mainStore.pushAlert('error', 'The route could not be changed. Try again');
    }
  }

  async fetchCourierByExternalId(externalId: string): Promise<Courier | null> {
    try {
      const resp = await CourierRequests.getByExternalId({ externalId });
      return resp.data.courier;
    } catch (error) {
      error && console.error(error);
      mainStore.errorHandler(error, 'fetchCourierByExternalId');
      return Promise.reject();
    }
  }

  async getJobTrip(jobId: string): Promise<GetTripDto | null> {
    if (!jobId.length) return null;

    try {
      const response = await JobRequests.getTrip(jobId);
      if (response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      error && console.error(error);
      return Promise.reject();
    }
  }

  getCurrentTeamTimezone() {
    const activeTeamId = authStore.activeTeamId;
    const team = this.teams?.find((w) => w.team.id === activeTeamId);

    if (!team) return DEFAULT_TIME_ZONE;

    return (
      this.warehouses?.find((w) => w.id === team.warehouse.warehouse.id)?.timezone ||
      DEFAULT_TIME_ZONE
    );
  }

  getEta(datetime?: number) {
    const timezone = this.getCurrentTeamTimezone();
    return datetime
      ? new Date((datetime || 0) * 1000)
          .toLocaleString('en-GB', { timeZone: timezone })
          .split(' ')[1]
          ?.slice(0, -3)
      : 0;
  }
}

export const dataStore = new DataStore();
