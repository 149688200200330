import { TaskKind, TaskState } from 'api/types/Task';
import { floatRound } from 'common/float-round';
import { frameDetailStore } from 'components/FrameDetail/store';
import { searchStore } from 'components/Search/store';
import { GOOGLE_API_KEY, MAP_CENTER, MAP_ZOOM, MOBILE_WIDTH } from 'config';
import { BBox } from 'geojson';
import GoogleMapReact from 'google-map-react';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { dataStore, DeliveryZoneItem, JobTrip } from 'stores/DataStore';
import { mainStore } from 'stores/MainStore';
import useSupercluster from 'use-supercluster';
import PinWh from '../../assets/img/pin_wh.svg';
import mapStyle from '../../mapStyle.json';
import { CourierClusterPin } from './CourierClusterPin';
import { CourierPin } from './CourierPin';
import { MapPin } from './MapPin';
import { TaskClusterPin } from './TaskClusterPin';
import { TaskPin, TaskPinProps } from './TaskPin';
import { PolygonItem } from '../../api/types/Polygon';
import { JobState } from 'api/types/Job';
import { useMediaQuery } from 'react-responsive';
/*
import { Map } from '@vis.gl/react-google-maps';

const MapTypeId = {
  HYBRID: 'hybrid',
  ROADMAP: 'roadmap',
  SATELLITE: 'satellite',
  TERRAIN: 'terrain',
};
type MapConfig = {
  id: string;
  label: string;
  mapId?: string;
  mapTypeId?: string;
  styles?: google.maps.MapTypeStyle[];
};
*/

export default observer(() => {
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` });
  const [mapCenter, setMapCenter] = useState<GoogleMapReact.Coords>(MAP_CENTER);
  const [mapZoom, setMapZoom] = useState<number>(MAP_ZOOM);
  const [mapBounds, setMapBounds] = useState<BBox>();
  const [mapApi, setMapApi] = useState<{ map: any; maps: any } | null>(null);
  const [jobTrip, setJobTrip] = useState<JobTrip | null>(null);
  const [jobTripPolyline, setJobTripPolyline] = useState<google.maps.Polyline>();
  const [courierTrack, setCourierTrack] = useState<{
    inProgress: GoogleMapReact.Coords[];
    assigned: GoogleMapReact.Coords[];
  }>({ inProgress: [], assigned: [] });
  const [extraTaskPins, setExtraTaskPins] = useState<TaskPinProps[] | null>(null);
  const lastSelectedPoint = useRef<PolygonItem>();
  const courierSupercluster = useSupercluster({
    points: dataStore.couriersPoints,
    bounds: mapBounds,
    zoom: mapZoom,
    options: { radius: 25, maxZoom: 20 },
  });

  const taskSuperCluster = useSupercluster({
    points: dataStore.tasksPoints,
    bounds: mapBounds,
    zoom: mapZoom,
    options: { radius: 25, maxZoom: 20 },
  });

  const getTaskClusterItems = (clusterId: number) => {
    try {
      return taskSuperCluster.supercluster?.getLeaves(clusterId, 1000);
    } catch {
      return null;
    }
  };

  const getCourierClusterItems = (clusterId: number) => {
    try {
      return courierSupercluster.supercluster?.getLeaves(clusterId, 1000);
    } catch {
      return null;
    }
  };

  const handleMapClick = () => {
    mainStore.setSelectedPointCoords(null);
    mainStore.setActiveDynamicFrame(null);
    mainStore.setSelectedCourier(null);
    mainStore.setSelectedOrder(null);
    mainStore.setOpenedCourierCluster(null);
    mainStore.setOpenedTaskCluster(null);
    mainStore.setIsMapDragged(false);
  };
  const handleMapChange = ({ zoom, bounds, center }: GoogleMapReact.ChangeEventValue) => {
    setMapCenter(center);
    setMapZoom(zoom);
    setMapBounds([bounds.sw.lng, bounds.sw.lat, bounds.ne.lng, bounds.ne.lat]);
  };

  const requestJobTrip = async (jobId: string) => {
    const trip = (await dataStore.getJobTrip(jobId))?.tripPoints;
    if (trip) setJobTrip({ trip, jobId });
  };

  const setIsMapDragged = useCallback(() => {
    mainStore.setIsMapDragged(true);
  }, []);

  useEffect(() => {
    if (!mapApi || !dataStore.teams || !dataStore.teams.length || dataStore.deliveryZones?.length) {
      return;
    }
    dataStore.requestDeliveryZones().catch((error) => error && console.error(error));
    mapApi.maps.Polygon.prototype.getBounds = function () {
      const bounds = new mapApi.maps.LatLngBounds();
      this.getPaths().forEach((p: any) => {
        p.forEach((element: any) => bounds.extend(element));
      });
      return bounds;
    };
    //eslint-disable-next-line
  }, [mapApi, dataStore.teams?.length]);

  useEffect(() => {
    if (!mapApi || !dataStore.activeWarehouseExternalUid) return;
    const deliveryZone: DeliveryZoneItem = dataStore.deliveryZones?.[
      dataStore.activeWarehouseExternalUid
    ] || {
      fillColor: '#179f72',
      polygons: [[dataStore.activeWarehouseCoord as PolygonItem]],
    };
    const polygons = [];
    deliveryZone.polygons.forEach((paths, color) => {
      const polygon = new mapApi.maps.Polygon({
        paths,
        strokeWeight: 0,
        fillColor: color,
        fillOpacity: 0.1,
        clickable: false,
      });
      polygon.setMap(mapApi.map);
      polygons.push(polygon);
    });

    mapApi.map.setCenter(dataStore.activeWarehouseCoord);
    mapApi.map.setZoom(14);

    return () => polygons.forEach((polygon) => polygon.setMap());
    //eslint-disable-next-line
  }, [mapApi, dataStore.activeWarehouseExternalUid, dataStore.deliveryZones]);

  useEffect(() => {
    if (!mainStore.selectedCourier.id || !dataStore.couriersPoints.length) {
      setCourierTrack({ inProgress: [], assigned: [] });
      return;
    }
    const { geometry } =
      dataStore.couriersPoints.find(
        (point) => point.properties.courierId === mainStore.selectedCourier.id,
      ) || {};
    if (!geometry) {
      setCourierTrack({ inProgress: [], assigned: [] });
      return;
    }
    const courierCoords: GoogleMapReact.Coords = {
      lat: geometry.coordinates[1],
      lng: geometry.coordinates[0],
    };
    mainStore.setSelectedPointCoords(courierCoords);
    if (!dataStore.tasksPoints.length) {
      setCourierTrack({ inProgress: [], assigned: [] });
      return;
    }
    const filteredPoints = dataStore.tasksPoints.filter((point) => {
      return point.properties.courierId === mainStore.selectedCourier.id;
    });
    const courierTasks = filteredPoints.map((point) => point.properties.task);

    if (!courierTasks.length) {
      setCourierTrack({ inProgress: [], assigned: [] });
      return;
    }
    const filteredInProgress = courierTasks.filter(({ task }) =>
      [TaskState.ON_THE_WAY, TaskState.ON_POINT].includes(task.state as TaskState),
    );
    let inProgressTaskCoords: GoogleMapReact.Coords[] = filteredInProgress.map(({ address }) => ({
      lat: address.latitude,
      lng: address.longitude,
    }));
    const assignedLocal = dataStore.getCourierJobs(mainStore.selectedCourier.id);
    let assignedTasksCoords: GoogleMapReact.Coords[] = [];
    assignedLocal.forEach((job) => {
      job.orders.forEach((order) => {
        order.tasks
          .filter(({ task }) => {
            return (
              [TaskState.UNASSIGNED, TaskState.ASSIGNED_TO_JOB, TaskState.ON_POINT].includes(
                task.state,
              ) && task.kind === TaskKind.PICK_UP
            );
          })
          .forEach((task) => {
            assignedTasksCoords.push({
              lat: task?.address.latitude || 0,
              lng: task?.address.longitude || 0,
            });
          });
      });
    });
    assignedLocal.forEach((job) => {
      job.orders.forEach((order) => {
        order.tasks
          .filter(({ task }) => {
            return (
              [TaskState.UNASSIGNED, TaskState.ASSIGNED_TO_JOB].includes(task.state) &&
              task.kind === TaskKind.DROP_OFF
            );
          })
          .forEach((task) => {
            assignedTasksCoords.push({
              lat: task?.address.latitude || 0,
              lng: task?.address.longitude || 0,
            });
          });
      });
    });
    if (inProgressTaskCoords.length) {
      inProgressTaskCoords = [courierCoords].concat(inProgressTaskCoords);
      if (assignedTasksCoords.length) {
        assignedTasksCoords = inProgressTaskCoords.slice(-1).concat(assignedTasksCoords);
      }
    } else if (assignedTasksCoords.length) {
      assignedTasksCoords = [courierCoords].concat(assignedTasksCoords);
    }
    setCourierTrack({ inProgress: inProgressTaskCoords, assigned: assignedTasksCoords });
    //eslint-disable-next-line
  }, [
    mainStore.selectedCourier.id,
    dataStore.couriersPoints,
    dataStore.tasksPoints,
    mainStore.isMapDragged,
  ]);

  useEffect(() => {
    if (!mainStore.selectedOrder.id || !dataStore.tasksPoints.length) return;
    const { geometry } =
      dataStore.tasksPoints.find(
        (point) => point.properties.taskId === mainStore.selectedOrder.taskId,
      ) || {};
    if (!geometry) return;
    mainStore.setSelectedPointCoords({
      lat: geometry.coordinates[1],
      lng: geometry.coordinates[0],
    });
    //eslint-disable-next-line
  }, [mainStore.selectedOrder.id, dataStore.tasksPoints]);

  useEffect(() => {
    if (!mapApi || (!courierTrack.inProgress.length && !courierTrack.assigned.length)) {
      return;
    }
    const trackInProgress = new mapApi.maps.Polyline({
      path: courierTrack.inProgress,
      icons: [
        {
          icon: {
            path: mapApi.maps.SymbolPath.FORWARD_OPEN_ARROW,
            scale: 1.4,
          },
          offset: '50%',
        },
      ],
      geodesic: false,
      strokeOpacity: 1,
      strokeWeight: 1,
      strokeColor: '#fff',
    });
    const trackAssigned = new mapApi.maps.Polyline({
      path: courierTrack.assigned,
      strokeOpacity: 0,
      icons: [
        {
          icon: {
            path: 'M 0,-1 0,1',
            strokeOpacity: 1,
            scale: 1,
            strokeColor: '#fff',
          },
          geodesic: false,
          offset: '0',
          repeat: '7px',
        },
      ],
    });
    trackInProgress.setMap(mapApi.map);
    trackAssigned.setMap(mapApi.map);
    return () => {
      trackInProgress.setMap();
      trackAssigned.setMap();
    };
  }, [mapApi, courierTrack]);

  useEffect(() => {
    if (!mapApi || !mainStore.selectedPointCoords || mainStore.isMapDragged) return;
    const newCenter = mainStore.activeDynamicFrame
      ? {
          lat: mainStore.selectedPointCoords.lat,
          // order card overlaps card info
          lng: mainStore.selectedPointCoords.lng,
        }
      : mainStore.selectedPointCoords;
    //FIXME: figure out why it's triggering with same latlng
    if (
      lastSelectedPoint.current &&
      lastSelectedPoint.current.lat === newCenter.lat &&
      lastSelectedPoint.current.lng === newCenter.lng
    )
      return;
    mapApi.map.setCenter(newCenter);
    lastSelectedPoint.current = newCenter;
    setMapZoom(15);
    //eslint-disable-next-line
  }, [mainStore.selectedPointCoords]);

  useEffect(() => {
    if (!jobTrip || !mapApi) return;
    jobTripPolyline?.setMap(null);

    let trip = jobTrip.trip;

    // if there is no RETURN-task, then we cut
    // all coordinates after DROPOFF-task completed time
    const dropOffCompleteTime = frameDetailStore.tasks?.DROP_OFF?.task.completedAt;
    if (!frameDetailStore.tasks?.RETURN && dropOffCompleteTime) {
      trip = jobTrip.trip.filter((point) => point.timestamp < dropOffCompleteTime);
    }

    const pathRaw: GoogleMapReact.Coords[] = trip
      .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
      .map((point) => ({
        lat: point.latitude,
        lng: point.longitude,
      }));
    // remove duplicates
    const roundPrecision = 30000;
    const pathResult: GoogleMapReact.Coords[] = [];
    pathRaw.forEach((c) => {
      const lats = pathResult.map((c) => floatRound(c.lat, roundPrecision));
      const lngs = pathResult.map((c) => floatRound(c.lng, roundPrecision));
      if (
        !lats.find((l) => floatRound(c.lat, roundPrecision) === l) &&
        !lngs.find((l) => floatRound(c.lng, roundPrecision) === l)
      ) {
        pathResult.push(c);
      }
    });

    setJobTripPolyline(
      new mapApi.maps.Polyline({
        path: pathResult,
        strokeOpacity: 1,
        strokeWeight: 1,
        strokeColor: '#FFF',
        geodesic: true,
      }),
    );

    if (pathResult.length > 1 && !dataStore.orderExistsLocally(mainStore.selectedOrder.id)) {
      //TODO: temporary removed to check if all is OK
      // mapApi.map.fitBounds(new mapApi.maps.Polygon({ paths: [pathResult] }).getBounds());
      mainStore.setSelectedPointCoords({ lat: pathResult[0].lat, lng: pathResult[0].lng });
    }

    return () => {
      jobTripPolyline?.setMap(null);
    };
    //eslint-disable-next-line
  }, [mapApi, jobTrip]);

  useEffect(() => {
    jobTripPolyline?.setMap(null);
    const selectedJob =
      searchStore.searchResponse?.jobs.find((job) =>
        job.orders.find((order) => order.order.id === mainStore.selectedOrder.id),
      ) ||
      dataStore.getJobListResponse?.find((job) =>
        job.orders.find((order) => order.order.id === mainStore.selectedOrder.id),
      );
    if (selectedJob && selectedJob?.job.state !== JobState.UNASSIGNED) {
      void requestJobTrip(mainStore.selectedOrder.jobId);
    }
    //eslint-disable-next-line
  }, [mainStore.selectedOrder.id, mapApi]);

  useEffect(() => {
    jobTripPolyline?.setMap(mapApi?.map);
    //eslint-disable-next-line
  }, [mapApi, jobTripPolyline]);

  useEffect(() => {
    setExtraTaskPins(null);
    // if (dataStore.orderExistsLocally(mainStore.selectedOrder.id)) return;

    const selectedJob =
      searchStore.searchResponse?.jobs.find((job) =>
        job.orders.find((order) => order.order.id === mainStore.selectedOrder.id),
      ) ||
      dataStore.getJobListResponse?.find((job) =>
        job.orders.find((order) => order.order.id === mainStore.selectedOrder.id),
      );
    const selectedOrder = selectedJob?.orders.find(
      (order) => order.order.id === mainStore.selectedOrder.id,
    );

    if (!selectedJob || !selectedOrder || !selectedOrder.tasks.length) return;
    const extraPins: TaskPinProps[] = selectedOrder.tasks
      .filter((task) => task.task.kind === TaskKind.DROP_OFF || task.task.kind === TaskKind.RETURN)
      .map((task) => ({
        lat: task.address.latitude,
        lng: task.address.longitude,
        orderId: selectedOrder.order.id,
        jobId: selectedJob.job.id,
        orderExternalId: selectedOrder.order.externalId,
        task,
      }));

    setExtraTaskPins(extraPins);

    mainStore.setSelectedPointCoords({ lat: extraPins[0].lat, lng: extraPins[0].lng });
    //eslint-disable-next-line
  }, [mainStore.selectedOrder.id]);

  return (
    <GoogleMapReact
      yesIWantToUseGoogleMapApiInternals
      bootstrapURLKeys={{
        key: GOOGLE_API_KEY,
        language: 'en-GB',
        region: 'GB',
      }}
      center={mapCenter}
      zoom={mapZoom}
      options={{
        clickableIcons: false,
        disableDefaultUI: true,
        gestureHandling: 'greedy',
        styles: mapStyle,
      }}
      onClick={handleMapClick}
      shouldUnregisterMapOnUnmount={true}
      onGoogleApiLoaded={setMapApi}
      onChange={handleMapChange}
      onDrag={setIsMapDragged}
    >
      {dataStore.activeWarehouseCoord && (
        <MapPin lat={dataStore.activeWarehouseCoord.lat} lng={dataStore.activeWarehouseCoord.lng}>
          <img className="map-pin _wh" src={PinWh} alt="" />
        </MapPin>
      )}
      {courierSupercluster.clusters?.map(({ geometry, properties }, index) => {
        if (!properties || !geometry) return <></>;
        const [lng, lat] = geometry.coordinates;
        const couriers = getCourierClusterItems(properties.cluster_id);
        if (properties.cluster && couriers) {
          return (
            <MapPin lat={lat} lng={lng} key={index}>
              <CourierClusterPin clusterId={properties.cluster_id} couriers={couriers} />
            </MapPin>
          );
        }
        return (
          <CourierPin
            lat={lat}
            lng={lng}
            teamId={properties.courier?.user?.teams[0]?.team?.id}
            id={properties.courierId}
            status={properties.courier.courier.status}
            vehicleType={properties.courier.courier.vehicleType}
            userName={properties.courier.user.contact.name}
            key={properties.courierId}
            isMobile={isMobile}
          />
        );
      })}
      {taskSuperCluster.clusters?.map(({ geometry, properties }, index) => {
        if (!properties || !geometry) return <></>;
        const [lng, lat] = geometry.coordinates;
        const orders = getTaskClusterItems(properties.cluster_id);
        if (properties.cluster && orders) {
          return (
            <MapPin lat={lat} lng={lng} key={index}>
              <TaskClusterPin clusterId={properties.cluster_id} orders={orders} />
            </MapPin>
          );
        }
        return (
          <TaskPin
            lat={lat}
            lng={lng}
            jobId={properties.jobId}
            orderId={properties.orderId}
            task={properties.task}
            key={properties.taskId}
            orderExternalId={properties.orderExternalId}
            isMobile={isMobile}
          />
        );
      })}
      {extraTaskPins?.length &&
        extraTaskPins.map((pinProps) => (
          <TaskPin
            showInfoBar={false}
            key={pinProps.task.task.id}
            isMobile={isMobile}
            {...pinProps}
          />
        ))}
    </GoogleMapReact>
  );
});
